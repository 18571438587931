import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firebase-messaging";
import "firebase/remote-config";
import { indexedDbSupported } from "./utils/IndexedDb";

const testConfig = {
    apiKey: "AIzaSyBGkZiy4t8aJbJLL3ngX2rzWXCYaDvteoE",
    authDomain: "qa-homework-app.firebaseapp.com",
    databaseURL: "https://qa-homework-app.firebaseio.com",
    projectId: "qa-homework-app",
    storageBucket: "qa-homework-app.appspot.com",
    messagingSenderId: "408119888521",
    appId: "1:408119888521:web:24e6500faade76c9b39458",
    measurementId: "G-V8ZCLFD36T",
};

const prodConfig = {
    apiKey: "AIzaSyDmn3tDvQB8XKEvAi6sV1pd9KIu2cwwfs0",
    authDomain: "homework-app-38140.firebaseapp.com",
    databaseURL: "https://homework-app-38140.firebaseio.com",
    projectId: "homework-app-38140",
    storageBucket: "homework-app-38140.appspot.com",
    messagingSenderId: "348208520326",
    appId: "1:348208520326:web:78ffc6feefad412b788507",
    measurementId: "G-3JDR4L982T",
};

let config = prodConfig;

let fire = firebase.initializeApp(config);
let analyticsSupported = firebase.analytics.isSupported();
let messagingSupported = firebase.messaging.isSupported();

let analytics;
analyticsSupported
    .then((val) => {
        if (val) {
            analytics = firebase.analytics();
        }
    })
    .catch(() => {});

let messaging;
if (messagingSupported) {
    messaging = firebase.messaging();
}

let remoteConfig;
indexedDbSupported()
    .then(() => {
        remoteConfig = firebase.remoteConfig();

        remoteConfig.settings = {
            minimumFetchIntervalMillis: 2000,
            fetchTimeoutMillis: 2000,
        };
    })
    .catch(() => {});

export {
    fire,
    analytics,
    analyticsSupported,
    messaging,
    messagingSupported,
    remoteConfig,
};
