import React from "react";

const StudentAppView = ({ children, loading = false }) => {
    return (
        <div className="studentcontainer unselectable">
            {loading ? (
                <div className="studentappview studentappviewloader">
                    <div className="studentScreenLoader" />
                </div>
            ) : (
                <div className="studentappview">{children}</div>
            )}
        </div>
    );
};

export default StudentAppView;
