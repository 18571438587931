import { clarity } from 'clarity-js';

let PROJECT_ID_QA = "dx7es0zudh";
let PROJECT_ID_PROD = "dx7tgp87bm";

clarity.consent();

clarity.start({
	projectId: PROJECT_ID_PROD,
	upload: 'https://m.clarity.ms/collect',
	track: true,
	content: true,
	//cookies: ['user_id', 'user_email', 'user_name']
});

export {
	clarity
};