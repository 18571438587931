let testDbName = "test_indexedDb_connection";
let testDbVersion = 4;
let testAppNameSpace = "test_namespace";
let testKey = "asdf";
let testValue = "1234";

export const indexedDbSupported = async () => {
    return new Promise((resolve, reject) => {
        try {
            openIndexedDb()
                .then((db) => {
                    setIndexedItem(db, testKey, testValue)
                        .then(() => {
                            deleteIndexedItem(db, testKey)
                                .then(() => {
                                    db.close();
                                    resolve();
                                })
                                .catch((reason) => {
                                    reject(reason);
                                });
                        })
                        .catch((reason) => {
                            reject(reason);
                        });
                })
                .catch((reason) => {
                    reject(reason);
                });
        } catch {
            reject("catched indexedDb support");
        }
    });
};

const openIndexedDb = async () => {
    return new Promise((resolve, reject) => {
        try {
            const request = window.indexedDB.open(testDbName, testDbVersion);
            request.onerror = (event) => {
                reject("onerror opening indexedDb");
            };
            request.onsuccess = (event) => {
                let db = request.result;
                resolve(db);
            };
            request.onupgradeneeded = (event) => {
                let db = event.target.result;

                if (!db.objectStoreNames.contains(testAppNameSpace)) {
                    db.createObjectStore(testAppNameSpace, {
                        keypath: "testKeys",
                    });
                }
            };
        } catch (e) {
            reject(e);
        }
    });
};

const setIndexedItem = async (db, key, value) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([testAppNameSpace], "readwrite");
        const objectStore = transaction.objectStore(testAppNameSpace);
        try {
            const request = objectStore.put(value, key);
            request.onerror = (event) => {
                reject("onerror setting item in indexedDb");
            };
            request.onsuccess = () => {
                resolve();
            };
        } catch (e) {
            reject(e);
        }
    });
};

const deleteIndexedItem = async (db, key) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([testAppNameSpace], "readwrite");
        const objectStore = transaction.objectStore(testAppNameSpace);
        try {
            const request = objectStore.delete(key);
            request.onerror = (event) => {
                reject("onerror deleting item in indexedDb");
            };
            request.onsuccess = () => {
                resolve(true);
            };
        } catch (e) {
            reject(e);
        }
    });
};
