import React from "react";
import StudentAppView from "../../layouts/StudentAppView";
import { createBrowserHistory } from "history";
import SentryErrorMain from "../../components/SentryError/SentryErrorMain/SentryErrorMain";

const history = createBrowserHistory({ forceRefresh: true });

const SentryError = () => {
    return (
        <StudentAppView>
            <SentryErrorMain history={history} />
        </StudentAppView>
    );
};

export default SentryError;
