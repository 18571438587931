import { analytics, analyticsSupported } from "../firebase";
import { clarity } from "./clarity";
import { serverStringTs } from "./config";
import axios from "axios";

const dumpEvent = (event_name, event_body, plugins = ["firebase"]) => {
    if (plugins.includes("mixpanel")) {
        axios
            .post(
                `${serverStringTs}/prod/sg/proxyAnalyticsEvent`,
                {
                    data: {
                        event_name,
                        properties: event_body,
                        plugins: ["mixpanel"],
                    },
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                console.log("dumping mixpanel event " + event_name);
            })
            .catch((err) => {
                console.log("analytics is not supported");
            });
    }

    if (plugins.includes("firebase")) {
        // fire event on firebase
        analyticsSupported
            .then((val) => {
                if (val) {
                    console.log("dumping event " + event_name);
                    analytics.logEvent(event_name, event_body);
                } else {
                    console.log("analytics is not supported");
                }
            })
            .catch((reason) => {
                console.log(reason);
            });
    }

    // fire event on clarity
    if (clarity.analyticsSupported) {
        console.log("dumping event on clarity" + event_name);
        clarity.event(event_name, event_body);
    }
};

export default dumpEvent;
