let testKey = "asdf";
let testValue = "1234";

export const setLocalItem = (key, value) => {
    if (localStorageSupported()) {
        window.localStorage.setItem(key, value);
        return {
            disabled: false,
        };
    } else {
        return {
            disabled: true,
        };
    }
};

export const getLocalItem = (key) => {
    let localItem = undefined;
    if (localStorageSupported()) {
        localItem = window.localStorage.getItem(key);
        return {
            disabled: false,
            value: localItem,
        };
    } else {
        return {
            disabled: true,
            value: localItem,
        };
    }
};

export const removeLocalItem = (key) => {
    try {
        window.localStorage.removeItem(key);
    } catch {
        // do nothing
    }
};

export const localStorageSupported = () => {
    try {
        window.localStorage.setItem(testKey, testValue);
        window.localStorage.removeItem(testKey);
        return true;
    } catch {
        return false;
    }
};
