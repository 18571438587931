import React from "react";
import * as Sentry from "@sentry/browser";
import SentryError from "../pages/Errors/SentryError";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, evendId: null };
    }
    componentDidCatch(error, errorInfo) {
        this.setState({ error });
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
        // Sentry.showReportDialog({ eventId: this.state.eventId });
    }
    render() {
        if (!this.state.error) {
            return this.props.children;
        } else {
            return <SentryError />;
        }
    }
}

export default ErrorBoundary;
