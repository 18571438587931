import React from "react";
import ReactDOM from "react-dom";
import WebFontLoader from "webfontloader";
import "./index.scss";
import App from "./app";
import { hideInstallablePrompt } from "./utils/installableFns";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";

WebFontLoader.load({
    google: {
        families: [
            "Montserrat:400, 600, 700, 800, 900",
            "Epilogue:400, 600, 700, 800, 900",
        ],
    },
});

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById("root")
);

serviceWorker.register();

hideInstallablePrompt();
