import React from "react";
import Lottie from "react-lottie";
import "./SentryErrorMain.scss";
import sadSmiley from "../../../assets/lotties/sad-smiley.json";
import { getLocalItem } from "../../../utils/localStorage";

const SentryErrorMain = ({ history }) => {
    const sadSmileyOptions = {
        loop: true,
        autoplay: true,
        animationData: sadSmiley,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const goToHomepage = () => {
        let hwProfiles = getLocalItem("homework_profile").value;
        if (hwProfiles) {
            hwProfiles = JSON.parse(hwProfiles);
            if ("student_to_classroom" in hwProfiles) {
                let profiles = hwProfiles.student_to_classroom;
                if (profiles.length > 1) {
                    history.push("/root");
                } else if (profiles.length === 1) {
                    history.push("/student/" + profiles[0].student_id);
                } else {
                    history.goBack();
                }
            }
        } else {
            history.goBack();
        }
    };

    const profilesExists = () => {
        let hwProfiles = getLocalItem("homework_profile").value;
        if (hwProfiles) {
            hwProfiles = JSON.parse(hwProfiles);
            if ("student_to_classroom" in hwProfiles) {
                let profiles = hwProfiles.student_to_classroom;
                if (profiles.length > 0) {
                    return true;
                }
            }
        }
        return false;
    };

    return (
        <div className="sentry-error-main">
            <div className="sentry-error-lottie-holder">
                <Lottie options={sadSmileyOptions} />
            </div>
            <div className="sentry-error-main-text">
                Error! Something went wrong.
            </div>
            <div className="sentry-error-sub-text">
                It looks like something went wrong.
                <br />
                Our team has been notified
            </div>
            <div className="sentry-error-cta-holder">
                <button className="sentry-error-cta" onClick={goToHomepage}>
                    {profilesExists() ? "Back to homepage" : "Back"}
                </button>
            </div>
        </div>
    );
};

export default SentryErrorMain;
